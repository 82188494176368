<script>
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import { formatDate } from '/~/utils/format/date'
import BaseHeader from '/~/components/base/header/base-header.vue'
import BaseAvatar from '/~/components/base/avatar/base-avatar.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import { useGroups } from '/~rec/composables/groups'

export default {
  name: 'rec-post-reporters-modal',
  components: {
    RecognitionHeader,
    BaseHeader,
    BaseAvatar,
    BaseMdl,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { groups, getMember } = useGroups()

    return {
      groups,
      formatDate,
      getMember,
    }
  },
  computed: {
    reporters() {
      return this.post.reporters.map((reporter) => {
        return {
          user: this.getMember(reporter.user_id),
          reported_at: reporter.reported_at,
        }
      })
    },
  },
}
</script>

<template>
  <base-mdl width="xs">
    <template #header>
      <base-header type="closable" class="text-white">
        <recognition-header icon="rec/round/alert" />
      </base-header>
    </template>
    <h2 class="text-center">
      <span>Reported by</span>
      <span class="text-eonx-neutral-600">({{ reporters.length }})</span>
    </h2>
    <div style="max-height: 15rem">
      <div
        v-for="(rep, idx) in reporters"
        :key="idx"
        class="mt-5 flex items-center"
      >
        <div class="mr-5">
          <base-avatar
            :src="rep.user.avatarUrl"
            :abbr="rep.user.initials"
            size="md"
          />
        </div>
        <div class="text-sm">
          <div class="font-bold">
            {{ rep.user.fullName }}
          </div>
          <div class="text-eonx-neutral-600">
            {{ formatDate('daymonthyeartime', rep.reported_at) }}
          </div>
        </div>
      </div>
    </div>
  </base-mdl>
</template>
